.Home {
  text-align: center;
  background-color: #cef5e5;
  max-width: 100vw;
}

.Home-logo img {
  display: block;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);
  max-width: 80%;
  height: auto;
  pointer-events: none;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

.Home-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(23, 54, 40);
}

.Home-button {
  min-width: 10vw;
  min-height: 7vh; 
  font-size: calc(5px + 2vmin);
}

.Home-footer {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: rgb(23, 54, 40);
}

@-webkit-keyframes fade {
  0% {opacity: 0;}
  14% {opacity: 1;}
  16.7% {opacity: 1;}
  23% {opacity: 0;}
  100% {opacity: 0;}
}
@keyframes fade {
  0% {opacity: 0;}
  14% {opacity: 1;}
  16.7% {opacity: 1;}
  23% {opacity: 0;}
  100% {opacity: 0;}
}

#f1 {
}
#f2 {
  -webkit-animation-delay: -0.5s;
}
#f3 {
  -webkit-animation-delay: -1s;
}
#f4 {
  -webkit-animation-delay: -1.5s;
}
#f5 {
  -webkit-animation-delay: -2s;
}
#f6 {
  -webkit-animation-delay: -2.5s;
}
#f7 {
  -webkit-animation-delay: -3s;
}
#f8 {
  -webkit-animation-delay: -3.5s;
}
#f9 {
  -webkit-animation-delay: -4s;
}
#f10 {
  -webkit-animation-delay: -4.5s;
}
