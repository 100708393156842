.TextInput {
    display: inline-grid;
    grid-template-columns: 35% 5% 40% 20%;
    text-align: center;
    max-width: 100vw;
    font-size: calc(15px + 2vmin);
    vertical-align: center;
  }

  .CorrectMark {
    color: rgb(52, 197, 112);
    text-align: left;
  }

  .IncorrectAnswer {
    color: rgb(217, 50, 38);
  }

  .IncorrectMark {
    color: rgb(217, 50, 38);
    text-align: left;
  }


  